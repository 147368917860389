<script>
export default {
  name: 'ContactVue',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      message_sent: false,
      message_sending: false,
      error_message: false
    }
  },
  methods: {
    show_error_message(message) {
      this.error_message = message
      setTimeout(() => {
        this.error_message = false
      }, 3000)
    },
    validateEmail() {
      return String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    redirect_url(url) {
      window.open(url)
    },
    async sendMessage() {
      if (!this.name) return this.show_error_message('Nom requis')
      if (!this.email) return this.show_error_message('Email requis')
      if (!this.message) return this.show_error_message('Message requis')
      if (!this.validateEmail(this.email)) return this.show_error_message('Email invalide')

      this.message_sending = true
      await fetch('https://hooks.zapier.com/hooks/catch/2603029/22pc5bo', {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          message: this.message,
          domain: window.location.host
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      this.message_sending = false
      this.message_sent = true
    }
  }
}
</script>

<template>
  <div id="contact">
    <div class="form_container" style="display: flex">
      <div class="text">
        <div class="title">Contactez-nous dès aujourd'hui</div>
        <div>Pour nous joindre, remplissez ce formulaire de contact</div>
        <div>
          <img
            class="profile_picture"
            src="/assets/paul.png"
            @click="redirect_url('https://www.linkedin.com/in/paul-bertrand-70983664/')"
          />
          <img
            class="profile_picture"
            src="/assets/maxence.png"
            @click="redirect_url('https://www.linkedin.com/in/maxence-douet/')"
          />
        </div>
      </div>
      <div class="form">
        <div style="display: flex; padding-bottom: 10px">
          <div style="flex: 1">
            <div>Nom</div>
            <input v-model="name" />
          </div>
          <div style="flex: 1; padding-left: 3vh">
            <div>Email</div>
            <input type="email" v-model="email" />
          </div>
        </div>
        <div>
          <div>Message</div>
          <textarea v-model="message"></textarea>
        </div>
        <div v-if="error_message" class="error_message">{{ error_message }}</div>
        <div class="button" @click="sendMessage">
          <i v-if="message_sending" class="pi pi-spin pi-spinner"></i>
          <span v-else-if="message_sent">Message envoyé !</span>
          <span v-else>Envoyer</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input,
textarea {
  background-color: #e8e8e8;
  border: 0px;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

input {
  height: 3vh;
}

textarea {
  height: 15vh;
}

#contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5vh 0vh;
  max-width: var(--max-width);

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }

  .ref {
    cursor: pointer;
  }

  .contact {
    text-decoration: none;
    cursor: pointer;
    padding: 5vh;
    color: black;
  }

  .ref:hover {
    text-decoration: underline;
  }

  .text {
    flex: 1;
    text-align: left;
    padding: 5vh;
  }

  .profile_picture {
    width: 75px;
    padding: 10px 10px 10px 0px;
    cursor: pointer;
  }

  .link {
    padding: 10px;
    /* flex: 1; */
  }

  .profile_picture:hover {
    opacity: 0.75;
  }

  .form {
    flex: 1;
    padding: 5vh;
    text-align: left;
  }

  .button {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 6px;
    margin-top: 5px;
    cursor: pointer;
    width: 175px;
  }

  .button:hover {
    opacity: 0.75;
  }

  .error_message {
    color: red;
  }
}

.bottom_links {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
}

@media (max-aspect-ratio: 0.8) {
  .form_container {
    flex-direction: column;
  }

  .bottom_links {
    flex-direction: column;
  }

  .link {
    padding: 10px;
  }
}
</style>
