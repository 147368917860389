<template>
  <div class="time" style="display: flex">
    <div class="content">
      <div class="title">
        Lancez votre programme de parrainage <span class="highlight">en moins d'1 heure</span>
      </div>
      <div class="subtitle">
        Le module Trustlead est <strong>plug & play</strong> : offrez à vos ambassadeurs une
        expérience in-app en moins d’une heure. Pour plus d’information,
        <span
          style="text-decoration: underline; cursor: pointer"
          onclick="window.open('https://calendly.com/paul-trustlead/45min')"
          >réservez une démo</span
        >.
      </div>
    </div>

    <div class="visual">
      <img
        style="width: 100%; cursor: pointer"
        src="/assets/code.png"
        onclick="window.open('https://www.npmjs.com/package/trustlead-js')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeVue'
}
</script>

<style scoped>
.time {
  display: flex;
  padding: 8vh 5vh 4vh 5vh;
  align-items: center;
  max-width: var(--max-width);

  .content {
    text-align: left;
    padding: 5vh;
    flex: 1;
  }

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }

  .subtitle {
    padding-top: 2vh;
  }
  .visual {
    padding: 5vh;
    flex: 1;
  }
}

@media (max-aspect-ratio: 0.8) {
  .time {
    padding: 0vh;
    flex-direction: column;
  }
}
</style>
