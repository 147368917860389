<script>
export default {
  name: 'ContactVue',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      message_sent: false,
      message_sending: false,
      error_message: false
    }
  },
  methods: {
    show_error_message(message) {
      this.error_message = message
      setTimeout(() => {
        this.error_message = false
      }, 3000)
    },
    validateEmail() {
      return String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    redirect_url(url) {
      window.open(url)
    },
    async sendMessage() {
      if (!this.name) return this.show_error_message('Nom requis')
      if (!this.email) return this.show_error_message('Email requis')
      if (!this.message) return this.show_error_message('Message requis')
      if (!this.validateEmail(this.email)) return this.show_error_message('Email invalide')

      this.message_sending = true
      await fetch('https://hooks.zapier.com/hooks/catch/2603029/22pc5bo', {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          message: this.message,
          domain: window.location.host
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      this.message_sending = false
      this.message_sent = true
    }
  }
}
</script>

<template>
  <div id="contact">
    <div class="bottom_links">
      <div class="link">Tous droits réservés © Trustlead 2024</div>
      <div
        class="link ref"
        @click="
          redirect_url(
            'https://trustlead.notion.site/Mentions-l-gales-5fed80c2464147358dd5ed27fa4e8d2d'
          )
        "
      >
        Mentions légales
      </div>
      <div
        class="link ref"
        @click="
          redirect_url(
            'https://trustlead.notion.site/Politique-de-confidentialit-des-donn-es-Trustlead-2edb748c5c4e430e8720b1a693dd7f0d'
          )
        "
      >
        Politique de confidentialité
      </div>
      <div
        class="link ref"
        @click="
          redirect_url(
            'https://trustlead.notion.site/Politique-de-gestion-des-cookies-de-Trustlead-f6dde4cbbd8047da9c70c1e0f55e0225'
          )
        "
      >
        Utilisation des cookies
      </div>
      <div class="link ref">
        <a class="contact" href="mailto:contact@trustlead.co?subject=Demande de contact"
          >contact@trustlead.co
        </a>
      </div>
      <div class="link" @click="redirect_url('https://www.linkedin.com/company/trustlead-co')">
        <img class="ref" src="/assets/linkedin.png" style="width: 30px" />
      </div>
    </div>
  </div>
</template>

<style scoped>
input,
textarea {
  background-color: #e8e8e8;
  border: 0px;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

input {
  height: 3vh;
}

textarea {
  height: 15vh;
}

#contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5vh 0vh;

  .title {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }

  .ref {
    cursor: pointer;
  }

  .contact {
    text-decoration: none;
    cursor: pointer;
    padding: 5vh;
    color: black;
  }

  .ref:hover {
    text-decoration: underline;
  }

  .text {
    flex: 1;
    text-align: left;
    padding: 5vh;
  }

  .profile_picture {
    width: 75px;
    padding: 10px 10px 10px 0px;
    cursor: pointer;
  }

  .link {
    padding: 20px 40px;
  }

  .profile_picture:hover {
    opacity: 0.75;
  }

  .form {
    flex: 1;
    padding: 5vh;
    text-align: left;
  }

  .button {
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 6px;
    margin-top: 5px;
    cursor: pointer;
    width: 175px;
  }

  .button:hover {
    opacity: 0.75;
  }

  .error_message {
    color: red;
  }
}

.bottom_links {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-aspect-ratio: 0.8) {
  .form_container {
    flex-direction: column;
  }

  .bottom_links {
    flex-direction: column;
  }

  .link {
    padding: 10px;
  }
}
</style>
