<template>
  <div class="boost" style="display: flex">
    <div class="visual">
      <img style="width: 100%" src="/assets/integrations.png" />
    </div>
    <div class="content">
      <div class="title">
        Gestion de votre programme en <span class="highlight">pilote automatique</span>
      </div>
      <div class="subtitle">
        Nos intégrations avec les principaux CRM et prestataires de paiement
        <strong>automatisent vos tâches manuelles</strong> de suivi et vous offrent une gestion
        simple et efficace.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeVue'
}
</script>

<style scoped>
.boost {
  display: flex;
  padding: 4vh 5vh 8vh 5vh;
  align-items: center;
  max-width: var(--max-width);

  video {
    width: 75%;
  }

  .content {
    text-align: left;
    padding: 5vh;
    flex: 1;
  }
  .title {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }
  .subtitle {
    padding-top: 2vh;
  }
  .visual {
    padding: 5vh;
    flex: 1;
  }
}

@media (max-aspect-ratio: 0.8) {
  .boost {
    padding: 0vh;
    flex-direction: column-reverse;
  }
}
</style>
