<template>
  <div class="why">
    <div class="container">
      <div class="title">Pourquoi lancer votre programme de parrainage ?</div>

      <div class="content">
        <div class="advantage">
          <img class="advantageImg" src="/assets/rocket.svg" />
          <div class="subtitle">Générez <strong>2 fois</strong> plus d'opportunités</div>
          <div class="text">
            Vos utilisateurs sont vos meilleurs ambassadeurs. Les engager, c’est générer des
            prospects qualifiés
          </div>
        </div>

        <div class="advantage">
          <img class="advantageImg" src="/assets/trust.svg" />
          <div class="subtitle">Améliorez votre taux de conversion de <strong>10%</strong></div>
          <div class="text">
            Un prospect parrainé par 1 utilisateur a <strong>5 fois plus de chance</strong> de
            devenir votre client
          </div>
        </div>

        <div class="advantage">
          <img class="advantageImg" src="/assets/cac.svg" />
          <div class="subtitle">Réduisez votre CAC de <strong>35%</strong></div>
          <div class="text">
            Pilotez la rémunération que vous accordez à vos ambassadeurs et automatisez votre suivi
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramComponent'
}
</script>

<style scoped>
.why {
  /* background-image: linear-gradient(to top, rgba(0, 255, 170, 0.755), #15afe4); */
  background-image: linear-gradient(to top, rgb(17, 17, 17), #353535);
  /* border-top: 1px solid black; */
  /* background-color: lightpink; */
  margin-top: 50px;
  color: white;
  display: flex;
  justify-content: center;
  width: 100vw;

  .container {
    padding: 50px 10px;
    max-width: var(--max-width);
  }

  .advantage {
    padding: 50px;
    flex: 1;
  }

  .advantageImg {
    height: 80px;
  }

  .title {
    padding-top: 30px;
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }

  .subtitle {
    font-size: 20px;
    font-weight: 400;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .text {
    font-size: 1.8vh;
    padding-top: 1vh;
  }
}

@media (max-aspect-ratio: 0.8) {
  .content {
    flex-direction: column !important;
  }
}
</style>
