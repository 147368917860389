import 'primeicons/primeicons.css'
import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import VueMobileDetection from 'vue-mobile-detection'
import posthog from 'posthog-js'
import router from './router'
const app = createApp(App)

app.use(VueMobileDetection)
app.use(router)
app.mount('#app')

if (process.env.NODE_ENV === 'production') {
  posthog.init('phc_EBr2xz46nhHvEtrRJU72h4TCOvhm8z77YI7YzLL86l1', {
    api_host: 'https://eu.i.posthog.com'
  })
}
