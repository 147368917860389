<script>
export default {
  name: 'SignupView'
}
</script>

<template>
  <form>
    <div class="form-group">
      <label for="username">Username:</label>
      <input type="text" id="username" name="username" required />
    </div>

    <div class="form-group">
      <label for="email">Email:</label>
      <input type="email" id="email" name="email" required />
    </div>

    <div class="form-group">
      <button type="button">Register</button>
    </div>
  </form>
</template>

<style scoped></style>
