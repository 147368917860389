<script>
export default {
  name: 'SignupViewHubspot',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '145053926',
          formId: 'c7e063d1-0ea5-4fd6-a7f0-6fbdb8af7425'
        })
      }
    })
  }
}
</script>

<template>
  <div id="hubspotForm" v-once></div>
</template>

<style scoped></style>
