<script>
const { VUE_APP_ORGANISATION_ID, VUE_APP_SERVER_URL, VUE_APP_WEBAPP_URL } = process.env
import Why from '../components/Why.vue'
import Time from '../components/Time.vue'
import Boost from '../components/Boost.vue'
import RSE from '../components/RSE.vue'
import Integrations from '../components/Integrations.vue'
import Free from '../components/Free.vue'
import Contact from '../components/Contact.vue'
import Bottom from '../components/Bottom.vue'
import posthog from 'posthog-js'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    Why,
    Time,
    Boost,
    RSE,
    Integrations,
    Free,
    Contact,
    Bottom
  },
  data() {
    return {
      SERVER_URL: VUE_APP_SERVER_URL,
      ORGANISATION_ID: VUE_APP_ORGANISATION_ID,
      VUE_APP_WEBAPP_URL
    }
  },
  methods: {
    select_tab(name) {
      document.getElementById(name).scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    async init_trustlead() {
      try {
        const token = localStorage.getItem('token')
        const custom_query = token ? `?token=${token}` : ''
        const { data: ambassador } = await axios(
          `${this.SERVER_URL}/demo/ambassador${custom_query}`
        )
        if (!ambassador || !ambassador.token) return

        localStorage.setItem('token', ambassador.token)

        window._trustlead?.init({
          organisationId: VUE_APP_ORGANISATION_ID,
          token: ambassador.token
        })

        posthog.identify(ambassador.id, { email: ambassador.email, domain: 'trustlead_website' })
      } catch (e) {
        return false
      }
    }
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      this.init_trustlead()
    })
  }
}
</script>

<template>
  <div class="header">
    <div class="logo">
      <img class="logoImg" src="/assets/logo-small.png" />
    </div>
    <div class="tabs" v-if="!$isMobile()">
      <div class="tab" @click="select_tab('solution')">Solution</div>
      <div class="tab" @click="select_tab('tarifs')">Tarif</div>
      <div class="tab" @click="select_tab('contact')">Contact</div>

      <a class="tab" href="https://partner.trustlead.co">Espace partenaire</a>
    </div>
    <div style="margin-left: auto; display: flex; align-items: center">
      <a class="test_trustlead" style="margin-right: 20px" :href="VUE_APP_WEBAPP_URL"
        >Connexion →</a
      >
    </div>
  </div>

  <div class="container">
    <div class="content">
      <div class="title">
        Lancez votre programme de parrainage <span class="hightlight">sans frais</span>
      </div>
      <div class="subtitle">
        <div>
          <div>
            Trustlead permet aux logiciels de créer leur
            <strong>programme de parrainage</strong> intégré à leur plateforme client.
          </div>
          <div>
            <strong>Installation sans frais en moins d'1 heure.</strong>
          </div>
        </div>
      </div>

      <div style="display: flex; align-items: center; padding: 3vh">
        <a class="button" href="https://calendly.com/paul-trustlead/45min">Réserver une démo</a>

        <div class="test_trustlead" onclick="window._trustlead?.show()">Tester →</div>
      </div>

      <div style="padding-top: 2vh">
        <video id="presentation_video" playsinline loop autoplay muted class="video">
          <source :src="`${this.SERVER_URL}/statics/video_figma.mp4`" type="video/mp4" />
        </video>
      </div>

      <Why />
      <Time id="solution" />
      <Boost />
      <RSE />
      <Integrations />
      <Free id="tarifs" />
      <Contact id="contact" />
      <Bottom />
    </div>
  </div>
</template>

<style scoped>
.logo {
  width: 130px;
  display: flex;
}

.logoImg {
  height: 40px;
  padding: 5px;
}

.button {
  background-color: black;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  padding: 10px 30px;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
}

.button:hover {
  opacity: 0.75;
}

.container {
  padding-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  font-size: 75px;
  padding-top: 55px;
  font-weight: 700;
  max-width: min(1300px, 90vw);
  line-height: 100px;
}

.calendly-inline-widget {
  margin-left: auto;
}

.hightlight {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #15afe4, #58f1c3);
}

.test_trustlead {
  padding-left: 30px;
  font-size: 20px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.test_trustlead:hover {
  text-decoration: underline;
}

.tabs {
  display: flex;
  flex: 1;
  justify-content: center;

  .tab {
    padding: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  .tab:hover {
    text-decoration: underline;
  }
}

.subtitle {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 20px;
  max-width: min(1300px, 95vw);
}

.header {
  display: flex;
  top: 0px;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid lightgrey;
  position: fixed;
  width: 100%;
  background-color: white;
}

.animated-logo {
  width: 30px;
}

video {
  pointer-events: none;
  display: block;
  margin: 0;
  border-radius: 20px;
  max-width: min(1000px, 95vw);
}

.content {
  max-width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-aspect-ratio: 0.9) {
  .logoImg {
    height: 30px;
  }
  .container {
    padding-top: 30px;
  }

  .content {
    max-width: 100%;
  }

  .title {
    font-size: 55px;
    line-height: 70px;
  }

  .subtitle {
    font-size: 17px;
  }

  .video {
    max-width: 100%;
  }
}
</style>
