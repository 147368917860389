<template>
  <div class="why">
    <div class="content">
      <div class="title">
        <div>Installation gratuite.</div>
        <div>Commission au succès.</div>
      </div>

      <div class="text">
        Notre modèle est basé sur le succès des recommandations de vos ambassadeurs avec une
        commission de 25%.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserComponent'
}
</script>

<style scoped>
.why {
  background-color: var(--trustlead-color);
  color: white;
  padding: 15vh 0vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    max-width: 500px;
    padding: 0vh 10vh;
  }
  .title {
    font-size: var(--title-size);
    font-weight: var(--title-font-weight);
  }
  .text {
    font-size: 2vh;
    padding-top: 1.5vh;
  }
}
</style>
